"use strict";

import {closeOverlay} from "./overlay";
import initModulesInScope from '@elements/init-modules-in-scope';

export function initInScope ($scope) {

    $scope.find('.js-navbar-toggle__menu').on('click', function (e) {
        e.preventDefault();
        let $this = $(this);

        if($this.hasClass('is-open')){
            $this.removeClass('is-open');
            $('body').removeClass('nav-open');

        }else{
            $this.addClass('is-open');
            $('body').addClass('nav-open');
            closeOverlay($('#cartOverlay'));
        }
    });

    if(matchMedia('(max-width: 767px)').matches){
        $scope.find('.js-navbar-toggle__subnav').on('click', function (e) {
            e.preventDefault();

            let navbarParent = $(this).closest('.js-navbar-toggle');
            let navItems = navbarParent.find('.js-navbar-toggle__subnav');
            let $thisLi = $(this).closest('.js-navbar-toggle__item');
            let navLevel = $thisLi.data('navbar-level');

            if($thisLi.hasClass('is-open')){
                navbarParent.removeClass('is-open');
                $thisLi.removeClass('is-open');

                $thisLi.find('.js-navbar-toggle').removeClass('is-open');

                if (navLevel === 1){
                    $('body').removeClass('nav-first-level-open');
                }
            }else{
                navItems.each(function (e){
                    $(this).closest('.js-navbar-toggle__item').removeClass('is-open');
                });

                $thisLi.addClass('is-open');
                navbarParent.addClass('is-open');
                if (navLevel === 1){
                    $('body').addClass('nav-first-level-open');
                }
            }
        });

    }

}

