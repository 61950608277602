import BlazeSlider from 'blaze-slider';
import fetch from '@elements/fetch';
import initModulesInScope from "@elements/init-modules-in-scope";
import {onEnterViewPort} from "@elements/viewport-utils";
import * as lazyImg from '@elements/lazy-img';

export function initInScope ($scope) {
    let teasers = $scope.find('.js-product-teaser');

    if(teasers.length > 0){
        teasers.each(function() {
            let teaser = $(this);
            let colorSlider = teaser.find('.js-product-teaser__slider');

            //init slider
            if(colorSlider.length > 0){
                initSlider(teaser);
            }else{
                let slideContainer = $(this).find('.js-product-teaser-slide-container');
                let slideLoading = $(this).find('.js-product-teaser-slide-container__loading');

                onEnterViewPort(slideContainer, function () {
                    slideLoading.prop('hidden', false);

                    fetch($(this).data('product-teaser-slide-url')).then(response => response.json()).then((data) => {
                        if (data.success) {
                            slideContainer.replaceWith(data.html);
                            initSlider(teaser);
                        }
                        slideLoading.prop('hidden', true);
                    });
                }, { offset: 300 });
            }
        });
    }


}

function initSlider(teaser){
    let colorSlider = teaser.find('.js-product-teaser__slider');
    let colorButtons = teaser.find('.js-product-teaser__color-button');
    let mainImage = teaser.find('.js-product-teaser__image');
    let loading = teaser.find('.js-product-teaser__loading');
    let link = teaser.find('.js-product-teaser__link');

    let slider = new BlazeSlider(colorSlider[0], {
        all: {
            loop: false,
            slidesToScroll: 1,
            slideGap: '4px',
            slidesToShow: 6,
            draggable: false,
        },
        '(max-width: 767px)': {
            draggable: true
        },
    });

    //change main image on click on color button with ajax request
    colorButtons.each(function() {
        let button = $(this);

        button.on('click', function(){
            let url = button.data('product-teaser-url');

            loading.prop('hidden', false);
            colorButtons.removeClass('is-active');
            button.addClass('is-active');

            fetch(url).then(response => response.json()).then((data) => {
                if (data.success) {
                    mainImage.html(data.html);
                    if(data.link){
                        link.attr('href', data.link);
                    }
                    lazyImg.initInScope(mainImage);

                    if(data.trackingData){
                        data.trackingData.datalayer.forEach(function(element){
                            window.dataLayer.push(element);
                        })
                    }

                    //exchange values in data-tracking-product-data
                    if(data.trackingProductData){
                        link.attr('data-tracking-product-data', JSON.stringify(data.trackingProductData));
                    }
                }
                loading.prop('hidden', true);
            });
        });
    });
}