"use strict";
import {activateScrollLock, deactivateScrollLock} from "@elements/scroll-lock";

let currentScrollPosition = 0;

export function initInScope ($scope) {
    $scope.find('.js-overlay').each(function (e){
        let $overlay = $(this),
            $isInProductGrid = $overlay.closest('.js-product-grid'),
            $scrollContent = $overlay.find('.js-overlay__scroll-body');

        if ($isInProductGrid && $scrollContent) {
            $scrollContent.on('scroll', function (evt){
                currentScrollPosition = evt.target.scrollTop;
            });

            $overlay.closest('.js-product-grid').on('product-grid.success', function (e){
                $scrollContent.scrollTop(currentScrollPosition);
            });
        }
    });

    $('.js-overlay__toggle').on('click', function (evt) {
        let $toggle = $(this);
        let $prevDefault = $toggle.data('overlay-prev-default');

        if ($prevDefault !== false) {
            evt.preventDefault();
        }

        evt.stopImmediatePropagation();
        let $target = $($toggle.data('overlay-target'));


        if (!$toggle.data('overlay-target')) {
            console.error('Can\'t show overlay. Attribute data-target is not set for', this);
            return;
        }

        if (!$target || !$target.length) {
            console.error('Can\'t show overlay. Could not find overlay "' + $toggle.data('overlay-target') + '" for', this);
            return;
        }


        if ($target.hasClass('is-open')) {
            $toggle.removeClass('is-open');
            closeOverlay($target);

        } else {
            $toggle.addClass('is-open');
            openOverlay($target);
        }

        //only for overlay in meili search modal
        let search = $('.js-meili-search');
        let grid = search.find('.js-product-grid');
        if(grid.length){
            if(search.hasClass('overflow-hidden')){
                search.removeClass('overflow-hidden')
            }else{
                search.scrollTop(0);
                search.addClass('overflow-hidden')

                $('.js-product-grid__submit-section-btn').on('click', submitBtnOff);
            }

            function submitBtnOff (evt) {
                search.removeClass('overflow-hidden');
                $('.js-product-grid__submit-section-btn').off('click', submitBtnOff);
            }
        }
    });


}

export  function openOverlay($target){
    $target.addClass('is-open');
    if (matchMedia('(max-width: 767px)').matches) {
        $('body').addClass('overlay-open');
        let $navbarToggle = $('.js-navbar-toggle__menu');
        if($navbarToggle.hasClass('is-open')){
            $navbarToggle.trigger('click');
        }
    }
}

export  function closeOverlay($target){
    $target.removeClass('is-open')
    $target.find('.js-alert-notification').remove();
    if (matchMedia('(max-width: 767px)').matches) {
        $('body').removeClass('overlay-open');

    }
}
