"use strict";

export function initInScope($scope) {

    if (!_config.currentSelectedCountry){
        try{
            if(!window.sessionStorage.getItem('countryModal')) {
                $scope.find('#modalCountrySelect').modal('show');
            }

            $scope.find('#modalCountrySelect').on('hidden.bs.modal', function (e) {
                window.sessionStorage.setItem('countryModal', true);
                //select country AT as fallback if user dismisses modal
                if(!window.sessionStorage.getItem('countrySelect')) {
                    window.sessionStorage.setItem('countrySelect', 'AT');
                }
            });
        }catch(e){
            console.log(e);
        }
    }

    $scope.find('.js-close-country-select').on('click', function (e) {
        let selectedCounty = $(this).data('country').toUpperCase();
        $('#modalCountrySelect').modal('hide');
        window.sessionStorage.setItem('countryModal', true);
        window.sessionStorage.setItem('countrySelect', selectedCounty);
        window.localStorage.setItem('currentCountry', selectedCounty);
    });

}