import {onEnterViewPort} from "@elements/viewport-utils";
import {debounce} from "debounce";
import throwError from "@elements/throw-error"

export function initInScope($scope) {
    let $elements = $scope.find('.js-tracking--impression');
    onEnterViewPort($elements, function (element) {
        if (typeof $(element).data('tracking-product-data') !== "object") {
            throwError('Impression tracking: data passed via tracking-product-data was no valid JSON. Please check for JSON validity', $(element).data('tracking-product-data'), element);
        }

        trackGTMImpressions([$(element).data('tracking-product-data')])
    })


    let $productClickElements = $scope.find('.js-tracking--product-click:not(.js-go-to-link), .js-tracking--product-click.js-go-to-link a'); /* workaround for go to link*/
    $productClickElements.on('click', function () {
        let $element = $(this).hasClass('js-tracking--product-click')
            ? $(this)
            : $(this).closest('.js-tracking--product-click');

        if ( $element.data('tracking-product-data') && typeof $element.data('tracking-product-data') !== "object") {
            throwError('Product click tracking: data passed via tracking-product-data was no valid JSON. Please check for JSON validity', $element.data('tracking-product-data'), $element[0]);
        }

        trackGTMProductClick($element.data('tracking-product-data'));
    })
}

const trackGTMProductClick = function (productData) {
    if (!window['dataLayer']) {
        return;
    }

    dataLayer.push({
        'event': 'productClick',
        'ecommerce': {
            'click': {
                'products': [productData]
            }
        },
    });
};

const trackGTMImpressions = (function () {
    let productImpressionsTrackingQueue = [];

    function trackGTMImpression(products) {
        productImpressionsTrackingQueue = [...productImpressionsTrackingQueue, ...products];

        debouncedImpressionTracking();
    }

    const debouncedImpressionTracking = debounce(() => {
        if (!window['dataLayer']) {
            return;
        }

        dataLayer.push({
            'event': 'productImpression',
            'ecommerce': {
                'currencyCode': _config.currencyCode,
                'impressions': productImpressionsTrackingQueue
            }
        });

        productImpressionsTrackingQueue = [];
    }, 700);

    return trackGTMImpression;
}());