"use strict";

export function initInScope() {
    checkScrollPosition();
    $(window).scroll(function(){
        checkScrollPosition();
    });
}

function checkScrollPosition() {
    if ($(window).scrollTop() >= 50) {
        $('.navbar').addClass('is-sticky');
        $('.js-meili-search').addClass('is-sticky');
    }
    else {
        $('.navbar').removeClass('is-sticky');
        $('.js-meili-search').removeClass('is-sticky');
    }
}