"use strict";
import lottie from "lottie-web";

let defaultOptions = {
    selector: '.js-loading-animation'
}

export function init(options = defaultOptions) {
    let $loadingAnimation = $(options.selector);

    $loadingAnimation.each(function (e){
        lottie.loadAnimation({
            container:  $(this)[0], // the dom element that will contain the animation
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/static/animation/loading-animation.json', // the path to the animation json
        });
    });
}