import * as gtm from './tracking/gtm';
import * as matomo from './tracking/matomo';
import $ from "jquery";
import {getPrefixedDataSet} from "@elements/data-set-utils";

export const initGtmEventTracking = createEventTracking(gtm);
export const initMatomoEventTracking = createEventTracking(matomo);

function createEventTracking({
                                 setup,
                                 trackEvent
                             }) {
    return ({
                dataAttributePrefix = 'event-tracking',
                debug = false
            } = {}) => {
        setup({debug});

        // todo repalce with onFind
        $(document.body).on('click', `[data-${dataAttributePrefix}-category]`, function () {

            let clickedElement = this;
            let $clickedElement = $(clickedElement);
            let data = {};

            let location = window.location.pathname;

            if (clickedElement.tagName === 'A') {
                data.action = trim(clickedElement.textContent);
                data.label = location;
            }

            data = {
                ...data,
                ...getPrefixedDataSet(dataAttributePrefix, $clickedElement)
            };

            trackEvent(data, {debug});
        })
    }
}

function trim(str) {
    return str.replace(/\s\s+/g, ' ').trim();
}

export function responseTracking(request) {
    // Unpack json response body if the promise was created via fetch
    let promise = request.then(response => (response
        && response.json
        && typeof response.json === 'function'
        && response.clone
        && typeof response.clone === 'function')
        ? response.clone().json()
        : response
    );

    promise.then(result => {
        if (result['__trackingScript']) {
            try {
                eval(result['__trackingScript']);
            } catch(e) {console.error(e)}
        }
    });
}