"use strict";

export function init() {
    /*!
 * jQuery Collapsable Breadcrumbs
 * Original author: jyoakum@siteworx.com
 * Depends on scss structure.  No inline styles.
 */
    (function($, window, document, undefined) {
        // Create the defaults once
        var pluginName = 'breadcrumb-collapsible',
            pluginCssBlock = '.' + pluginName,
            cssExpanded = pluginName + '_expanded',
            cssToggle = pluginName + '__toggle',
            cssOverflow = pluginName + '_overflow',
            defaults = {
                /*propertyName: "value"*/
            };

        // The actual plugin constructor
        function Plugin(element, options) {
            this.element = element;
            this.$element = $(element);
            this.options = $.extend({}, defaults, options);
            this._defaults = defaults;
            this._name = pluginName;

            this.init();
        }

        Plugin.prototype = {

            init: function() {
                this.injectExpander(this.$element);
                this.checkOverflow();
                var self = this;

                var lazyResize = $.proxy(this.debounce(this.checkOverflow, 300), self);
                var lazyToggle = $.proxy(this.doToggle, self);
                this.$element.on('click.' + pluginName, '.' + cssToggle, lazyToggle);
                $(window).on('resize.' + pluginName, lazyResize);
            },

            isOverflowed: function(el) {
                // Determins if we have overflow
                return el.scrollHeight > el.clientHeight || el.scrollWidth > el.clientWidth;
            },

            checkOverflow: function() {
                if (this.isOverflowed(this.element)) {

                    let $breadcrumb = $(this.element);
                    let $breadcrumbItems = $breadcrumb.find('li');

                    let length = $breadcrumbItems.length - 1;
                    let breadcrumbWidth = $breadcrumb.width();
                    let width = 0;

                    for (let i =0; i <= length; i++){
                        width = width + $breadcrumbItems[length-i].clientWidth;
                    }

                    if(width > breadcrumbWidth){
                        this.$element.addClass(cssOverflow);

                    }else{
                        this.$element.addClass('last-3');
                        this.$element.addClass(cssOverflow);
                    }

                } else {
                    this.$element.removeClass(cssOverflow);
                    // double check to make sure that that toggle is still valid
                    if (this.isOverflowed(this.element)) {
                        this.$element.addClass(cssOverflow);
                    }
                }
            },

            doCollapse: function() {
                this.$element
                    .addClass(cssExpanded);
            },

            doExpand: function() {
                this.$element
                    .removeClass(cssExpanded);
            },

            doToggle: function(evt) {
                evt.preventDefault();
                var $el = this.$element;

                if ($el.hasClass(cssExpanded)) {
                    this.doExpand();
                } else {
                    this.doCollapse();
                }

            },

            injectExpander: function($el) {
                if (!$el.has(cssToggle).length) {
                    $el
                        .children(':first')
                        .after($('<li class="' + cssToggle + '"><a href="#"></a></li>'));
                }
            },

            debounce: function(func, wait, immediate) {
                var timeout, args, context, timestamp, result;

                var now = Date.now || function() {
                    return new Date().getTime();
                };

                var later = function() {
                    var last = now() - timestamp;

                    if (last < wait && last >= 0) {
                        timeout = setTimeout(later, wait - last);
                    } else {
                        timeout = null;
                        if (!immediate) {
                            result = func.apply(context, args);
                            if (!timeout) context = args = null;
                        }
                    }
                };

                return function() {
                    context = this;
                    args = arguments;
                    timestamp = now();
                    var callNow = immediate && !timeout;
                    if (!timeout) timeout = setTimeout(later, wait);
                    if (callNow) {
                        result = func.apply(context, args);
                        context = args = null;
                    }

                    return result;
                };
            }
        };

        $.fn[pluginName] = function(options) {
            return this.each(function() {
                if (!$.data(this, 'plugin_' + pluginName)) {
                    $.data(this, 'plugin_' + pluginName,
                        new Plugin(this, options));
                }
            });
        };

        // AutoInit based on predefined class (.breadcrumb-collapsible). Remove if you don't want this
        $(document).ready(function() {
            $(pluginCssBlock)[pluginName]();
        });

    })(jQuery, window, document);
}

