"use strict"
import initModulesInScope from "@elements/init-modules-in-scope";
let isMobile = window.matchMedia("(max-width: 767px)").matches;

export function initInScope ($scope) {
    let $filterDropdown = $scope.find('.js-product-grid__dropdown-count');

    if(isMobile){
        let $filterInputs = $scope.find('.js-product-grid__input');
        let filterCount = 0;

        // For total count
        $filterInputs.each(function () {
            //check if input is checked
            if($(this).is(':checked')){
                filterCount++;
            }

            // add on click event
            $(this).on('change', function () {
                if($(this).is(':checked')){
                    filterCount++;
                }else{
                    filterCount--;
                }
                setTotalCount(filterCount);
            });

            setTotalCount(filterCount);
        });
    }

    // for count per dropdown filter
    $filterDropdown.each(function () {
        let $dropdown = $(this);

        let $filterDropdownInputs = $(this).find('.js-product-grid__input');
        let filterCountPerDropdown = 0;

        $filterDropdownInputs.each(function () {

            //check if input is checked
            if($(this).is(':checked')){
                filterCountPerDropdown++;
            }

            // add on click event
            $(this).on('change', function () {
                if($(this).is(':checked')){
                    filterCountPerDropdown++;
                }else{
                    filterCountPerDropdown--;
                }
                setCountPerDropdown($dropdown, filterCountPerDropdown);
            });

            setCountPerDropdown($dropdown, filterCountPerDropdown);
        });

    });

}

//set count function
function setCountPerDropdown($dropdown, count) {
    if(count > 0){
        $dropdown.find('.js-filter-counter').removeClass('filter-dropdown__counter--opacity');


    }else{
        $dropdown.find('.js-filter-counter').addClass('filter-dropdown__counter--opacity');

    }
    $dropdown.find('.js-filter-counter__text').text(count);
}

function setTotalCount(count) {
    if(count > 0){
        $('.js-filter-total-counter').removeAttr('hidden');

        if(isMobile){
            let $qickFilterAccordion = $('.js-filter-counter__quick-filter-accordion');
            $qickFilterAccordion.addClass('show');
        }

    }else{
        $('.js-filter-total-counter').attr('hidden', 'hidden');

        if(isMobile){
            $('.js-filter-counter__quick-filter-accordion').removeClass('show');
        }
    }
    $('.js-filter-total-counter__text').text(count);
}