import {getInputState, removeInput} from "./product-grid";

const defaultSelectors = {
    base: '.js-product-grid-quick-filter',
    container: '.js-product-grid',
    template: '.js-product-grid-quick-filter__template',
    text: '.js-product-grid-quick-filter__text',
    removeAllButton: '.js-product-grid-quick-filter__remove-all'
};

export function createQuickFilter(options = {}, selectors = {}, templates = {}) {
    options = {
        ...defaultSelectors,
        ...options
    };

    selectors = {
        ...defaultSelectors,
        ...selectors
    };

    templates = {
        ...templates
    };

    function flat(obj) {
        let {parent} = obj;
        let array = [];

        array.push(obj);

        if (parent) {
            parent.isParent = true;
            array = flat(parent).concat(array);
        }

        return array;
    }

    function renderQuickFilters($quickFilter, $productGrid) {
        $quickFilter.empty();

        let quickFilter = [];
        if(_config.quickFilterHierarchy) {
            let {attributes, ...groups} = _config.quickFilterHierarchy;
            let filter = [...Object.values(groups).filter(x => !!Object.entries(x).length)];

            quickFilter = filter.map(function (group) {
                return flat(group);
            });

            if (attributes) {
                attributes = Object.values(attributes).filter(x => !!Object.entries(x).length);
                quickFilter = quickFilter.concat([attributes]);
            }

            for (let i = 0; i < quickFilter.length; i++) {
                let group = [];
                quickFilter[i].forEach(function (filter) {
                    group.push(filter)
                });

                if(templates.linkTemplate) {
                    $quickFilter.append(templates.linkTemplate(group));
                }
            }
        }

        if(templates.defaultTemplate) {
            let filter = [];
            getInputState($productGrid).forEach(function (inputState) {
                if (!inputState.hidden) {
                    filter.push(inputState);
                }
            });

            $quickFilter.append(templates.defaultTemplate(filter));

            $quickFilter.find('.js-product-grid-quick-filter__item').on('click', function () {
                let $this = $(this);
                if ($this.data('filter-name').indexOf('[]')) {
                    removeInput($productGrid, $this.data('filter-name'), $this.data('filter-value').toString());
                } else {
                    removeInput($productGrid, $this.data('filter-name'));
                }
            });
        } else {
            getInputState($productGrid).forEach(function (inputState) {
                if (!inputState.hidden) {
                    let $newButton = createQuickFilter(inputState);
                    $newButton.on('click', function () {
                        if (inputState.name.indexOf('[]')) {
                            removeInput($productGrid, inputState.name, inputState.value);
                        } else {
                            removeInput($productGrid, inputState.name);
                        }
                    });

                    $quickFilter.append($newButton);
                }
            });
        }

        // get the visible quickfilters
        let visibleFilters = [];
        getInputState($productGrid).forEach(function (inputState) {
            if (!inputState.hidden) {
                visibleFilters.push(inputState);
            }
        });

        // show/hide remove-all-button according to the number of visible quickfilters
        if (visibleFilters.length === 0) {
            $(selectors.removeAllButton).attr('hidden', true);
        } else {
            $(selectors.removeAllButton).attr('hidden', false);
        }
    }

    function createQuickFilter(inputState) {
        let $newQuickFilterButton = $($(selectors.template).html());
        $newQuickFilterButton.find(selectors.text).html(inputState.text);
        return $newQuickFilterButton;
    }

    return {
        initInScope: function ($scope) {
            $scope.find(selectors.base).each(function () {
                let $quickFilter = $(this);
                let $productGrid;

                if ($quickFilter.data('product-grid')) {
                    $productGrid = $($quickFilter.data('product-grid'));
                    if (!$productGrid || !$productGrid.length) {
                        console.error('Can\'t find product grid with selector "' + $quickFilter.data('product-grid')
                            + '" for quickfilter', $quickFilter);
                    }

                    return;
                } else {
                    $productGrid = $quickFilter.closest(selectors.container);
                }

                if (!$productGrid || !$productGrid.length) {
                    console.error('Can\'t find product grid for quickfilter. Add a data-product-grid selector or place the element inside a ' + selectors.container, $quickFilter);
                    return;
                }

                $productGrid.on('product-grid.changed', function () {
                    renderQuickFilters($quickFilter, $productGrid);
                });

                renderQuickFilters($quickFilter, $productGrid);
            });
        }
    };
}

export const initInScope = createQuickFilter().initInScope;