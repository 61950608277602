"use strict";

export function initInScope($scope) {
    if(matchMedia('(max-width: 767px)').matches){
        $scope.find('.js-footer-collapse').collapse();

        $scope.find('.js-footer-collapse__btn').on('click', function() {
            let $thisBtn = $(this);
            if($thisBtn.hasClass('collapsed')){
                $thisBtn.removeClass('collapsed');
            }else{
                $thisBtn.addClass('collapsed');
            }
            let $target = $($(this).data('target'));
            $target.collapse('toggle');
        });
    }

}