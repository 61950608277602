"use strict"

import * as lottieAnimation from './lottie-animation.js';

let searchToggleTarget;
let originalHref = window.location.href;
export function init () {
    let searchModal = $('.js-meili-search'),
        searchForm = searchModal.find('.js-meili-search-form'),
        ajaxFormContainer = searchForm.closest('.js-ajax-form'),
        searchInput = searchForm.find('.js-meili-search__input'),
        submitBtn = searchForm.find('.js-meili-search-form__submit-btn'),
        resetBtn = searchForm.find('.js-meili-search__reset-btn'),
        searchTerms = $('.js-meili-search-terms').find('.js-meili-search-terms__item'),
        searchTermsContainer = $('.js-meili-search__search-term-container');

    // remove the modal backdrop, focus the input and submit the form
    searchModal.on('shown.bs.modal', function () {
        searchForm.submit();
        setTimeout(() => {
            searchInput.focus();
        }, 100);
    });

    //remove all params on modal close
    searchModal.on('hide.bs.modal', function () {
        window.history.replaceState(null, '', originalHref);
    });
    
    // hide the modal on click outside of it
    $('.js-meili-search__toggle').on('click', function (e) {
        searchToggleTarget = $(this).data('target');
        let $searchToggleTarget = $($(this).data('target'));

        if($searchToggleTarget.hasClass('show')) {
            $searchToggleTarget.modal('hide');
            $('body').off('click', bodyClickHandler);
        }else{
            $searchToggleTarget.modal('show');
            $('body').on('click', bodyClickHandler);
        }
    });

    function bodyClickHandler (evt) {
        if ($(evt.target).parents(searchToggleTarget).length < 1 &&
            !$(evt.target).is(searchToggleTarget) &&
            !$(evt.target).hasClass('js-meili-search__toggle') &&
            $(evt.target).parents('.js-meili-search__toggle').length < 1 &&
            !$(evt.target).hasClass('js-product-grid-quick-filter__text') &&
            !$(evt.target).hasClass('js-product-grid-quick-filter__item') &&
            !$(evt.target).hasClass('product-grid-quick-filter__remove') &&
            !$(evt.target).hasClass('js-meili-search-typeahead__item') &&
            !$(evt.target).hasClass('js-product-grid__submit-section-btn') &&
            !$(evt.target).hasClass('js-product-grid__remove-input') &&
            !$(evt.target).hasClass('product-grid-quick-filter__remove-btn-text') &&
            $(evt.target).parents('.js-meili-search-typeahead__item').length < 1) {

            evt.preventDefault();

            searchModal.modal('hide');
            $('body').off('click', bodyClickHandler);
        }
    }

    // show reset-button and search-terms-container
    searchForm.on('input', function () {
        resetBtn.removeClass('d-none');
        searchTermsContainer.removeClass('d-none');
    });

    //defocus input on submit
    ajaxFormContainer.on('submit.ajax-form', function () {
        searchInput.blur();
    });

    //init loading spinner for product grid once
    ajaxFormContainer.on('fetched.ajax-form', function () {
        lottieAnimation.init({selector: '.js-loading-animation-product-grid'});
    });

    // Mobile: hide search-terms-container on click on submit-button
    submitBtn.on('click', function(evt) {
        if(matchMedia('(max-width: 767px)').matches) {
            searchTermsContainer.addClass('d-none');
        }
    });

    // show search-terms-container
    resetBtn.on('click', function () {
        searchTermsContainer.removeClass('d-none');
        $('.js-meili-search__input').typeahead('val', '');
        searchForm.submit();
        setTimeout(() => {
            searchInput.focus();
        }, 100);
    });

    // write search-term into input and submit
    searchTerms.each(function(index, item) {
        $(item).on('click', function() {
            let term = $(this).text();
            $('.js-meili-search__input').typeahead('val', term);
            searchForm.submit();
            resetBtn.removeClass('d-none');
            if(matchMedia('(max-width: 767px)').matches) {
                searchTermsContainer.addClass('d-none');
            }
        });
    });
}