"use strict";

import initModulesInScope from "@elements/init-modules-in-scope";

export function initInScope ($scope) {
    let platform = (window.navigator && window.navigator.userAgentData && window.navigator.userAgentData.platform) || window.navigator.platform;
    let macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'macOS', 'iPhone', 'iPad', 'iPod', 'iOS'];

    let isMacOS = false;
    $.each(macosPlatforms, function(index, value) {
        if (platform.indexOf(value) !== -1) {
            isMacOS = true;
            return false;
        }
    });

    if (isMacOS) {
        filterDropdown ();
    }else{
        if(window.matchMedia('(max-width: 767px)').matches){
            filterDropdown ();
        }
    }
     function filterDropdown () {
         let $filterDropdown = $scope.find('.js-filter-dropdown');
         $filterDropdown.each(function () {
             let $dropdown = $(this),
                 $dropdownToggle = $dropdown.find('.js-filter-dropdown__toggle'),
                 $dropdownMenu = $dropdown.find('.js-filter-dropdown__menu');

             $dropdownToggle.on('click touchstart', function (evt) {
                 evt.preventDefault();
                 evt.stopImmediatePropagation();

                 $dropdown.toggleClass('show');
                 $dropdownMenu.toggleClass('show');
             });
         });
     }
}