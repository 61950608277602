"use strict";

import {getPrefixedDataSet} from '@elements/data-set-utils';
import initModulesInScope from "@elements/init-modules-in-scope";

const defaultSelectors = {
    base: '.js-consent',
    template: '.js-consent__template',
    dataset: 'consent',
    button: '.js-consent__accept-btn'
};

export function initInScope($scope) {
    $scope.find(defaultSelectors.base).each(function () {
        createConsentOverlay(this, {revalidateEvent: 'CookiebotOnConsentReady'}, defaultSelectors);
    });
}

function createConsentOverlay(baseElement, options, selectors) {
    let data = getPrefixedDataSet(selectors.dataset, $(baseElement))
    let button = $(baseElement).find(selectors.button);

    if (data.lightbox) {
        let lightbox = $(baseElement).closest('.js-lightbox');
        $(lightbox).on('onAfterOpen.lg', function () {
            let overlay = $('.lg').find(selectors.base);
            if (overlay.length > 0) {
                initModulesInScope(overlay.parent());
            }
        })
    }

    if (Cookiebot.consent[data.service]) {
        renderTemplate(baseElement, {base: selectors.base, template: selectors.template});
    } else {
        if ($(baseElement).attr('hidden')) {
            $(baseElement).removeAttr('hidden', baseElement);
        }
    }

    $(window).on(options.revalidateEvent, function () {
        if (Cookiebot.consent[data.service]) {
            renderTemplate(baseElement, {base: selectors.base, template: selectors.template});
        } else {
            if ($(baseElement).attr('hidden')) {
                $(baseElement).removeAttr('hidden', baseElement);
            }
        }
    });

    if (button) {
        $(button).on('click', function () {
            let consent = data.service;
            let preferences = consent === 'preferences';
            let statistics = consent === 'statistics';
            let marketing = consent === 'marketing';

            Cookiebot.submitCustomConsent(preferences, statistics, marketing);
        });
    }

}


function renderTemplate(renderItem, selectors) {
    let template = $(renderItem).find(selectors.template);

    if (template) {
        let parent = $(renderItem).parent();
        if (parent.length > 0) { // add this check to prevent parent being null
            //everything about the overlay will be overwritten
            $(parent).html($(template).html());
            if (renderItem) {
                initModulesInScope($(renderItem).parent());
            } else {
                initModulesInScope(parent)
            }
        }
    }
}
