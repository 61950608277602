'use strict';

import Bloodhound from 'corejs-typeahead';
import {translate} from '@elements/translations';
import 'whatwg-fetch'; // IE10 Polyfill
import lottie from "lottie-web";
const defaultValues = {
    hint: true,
    highlight: true,
    minLength: 1,
    autocompleteText: 'title'
};
const defaultLayout = {
    headerLayout : (data, category) => {
        return(`<div class="typeahead-heading--meili-search d-flex justify-content-between">
            ${category.title ? (
            `<span class="typeahead-heading__icon text-primary ' + category.icon + '"> ${category.title}</span>`
        ) : ''}
            </div>`)
    },
    suggestionLayout: (data) => {
        // remove spaces and put optional spaces between all characters
        // so the query M10x60 will also find "M10 x 60"

        let regexStr = escapeRegExp(data._query);
        // remove all spaces
        // split with regex, so escaped chars don't get separated
        // -> get every non-whitespace character that has 0 or 1 \ before
        // -> then join them and put a optional white space between the characters
        // -> replace "*" php-wildcard (escaped \*) with regex-wildcard .*

        regexStr = regexStr.match(/(\\?\S)/g).join('\\s?').replace(/\\\*/g, '\.\*');
        let regexp = new RegExp(regexStr, 'gi');

        if (data.html) {
            return (
                `<div class="typeahead-suggestion--meili-search tt-product__title--meili-search js-meili-search-typeahead__item">
                    ${data.html.replace(regexp, '<mark class="tt-highlight--meili-search">$&</mark>')}
                </div>`
            )
        }

        return `<div class="typeahead-suggestion--meili-search js-meili-search-typeahead__item"><div class="tt-product__title--meili-search">${ data.html }</div></div>`;

    },
    pendingLayout: (data)=>{
        return (`<div class="loading-spinner w-100"><div class="meili-search-typeahead__loading-spinner js-meili-search-typeahead__loading-spinner loading-animation" data-bm-renderer='svg'></div></div>`);

    },
    errorLayout: () => {
        return false;
    }
};

export function createInitInScope(defaultOptions = defaultValues, overrideLayout = {}) {
    return function () {
        const $input = $('.js-typeahead__input');
        const categories = $input.data('categories');

        const layout = {
            ...defaultLayout,
            ...overrideLayout
        };

        if (!categories || !Array.isArray(categories)) {
            return;
        }

        let options = {
            ...defaultValues,
            ...defaultOptions
        };
        let dataSets = [];

        categories.map( (category) => {
            let bloodhound = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace('text'),
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                remote: {
                    url: $input.data('queryurl'),
                    wildcard: '%QUERY',
                    transport: fetchTransport,
                    rateLimitWait: 50,
                    filter: function (response) {
                        if (response && response[category.name] && response[category.name]) {
                            return response[category.name];
                        }
                        return [];
                    }
                }
            });

            dataSets.push({
                name: category.name,
                limit: Number.MAX_VALUE, /*workaround for https://github.com/twitter/typeahead.js/issues/1232*/
                display: defaultOptions.autocompleteText,
                source: bloodhound,
                templates: {
                    empty: (data) => layout.errorLayout(data),
                    header: (data) => {
                        if(category.overviewUrl){
                            data.overviewUrl = category.overviewUrl
                                + (category.overviewUrl.indexOf('?') >= 0 ? '&' : '?' )
                                + 'q=' + data.query;
                        }else{
                            data.overviewUrl = '';
                        }
                        if(!category.title){
                            category.title = '';
                        }

                        return layout.headerLayout(data, category);
                    },
                    suggestion: (data) => layout.suggestionLayout(data, category),
                    pending: (data) => layout.pendingLayout(data),
                    notFound: (data) => $('.js-typeahead__not-found')[0].innerHTML,

                }
            })
        });

        $input.typeahead(options, dataSets)
            .on('typeahead:asyncrequest', () => {
                let $loadingAnimation = $('.js-meili-search-typeahead__loading-spinner');

                $loadingAnimation.each(function (e){
                    lottie.loadAnimation({
                        container:  $(this)[0],
                        renderer: 'svg',
                        loop: true,
                        autoplay: true,
                        speed: 1,
                        direction: -1,
                        path: '/static/animation/loading-animation.json',
                    });
                });
            })
            .on('typeahead:asynccancel typeahead:asyncreceive', () => {
                console.log('hide loading...');
            })
            .on('typeahead:select typeahead:autocompleted', function (typeaheadObj, data) {
                // if (data['url']) {
                //     location.href = data.url;
                // }
                let listbox = $('#q_listbox');
                listbox.css('display', 'block');
                $input.val(data.title);
                $input.closest('form').submit();
                let searchTermsContainer = $('.js-meili-search__search-term-container');
                if(matchMedia('(max-width: 767px)').matches) {
                    searchTermsContainer.addClass('d-none');
                }
            })
            .on('typeahead:close', ()=>{
                console.log('close');
            });
    }
}

export const initInScope = createInitInScope();

const matchOperatorsRegex = /[|\\{}()[\]^$+*?.-]/g;

function escapeRegExp(string) {
    if (typeof string !== 'string') {
        throw new TypeError('Expected a string');
    }

    return string.replace(matchOperatorsRegex, '\\$&');
}



function fetchTransport(opts, onSuccess, onError) {
    fetch(opts.url, {
        type: "GET",
    })
        .then(respones => respones.json())
        .then(onSuccess)
        .catch(onError)
}
