"use strict";

import {onEnterViewPort} from "@elements/viewport-utils";
import initModulesInScope from '@elements/init-modules-in-scope';

export function initInScope($scope) {

    let $renderTemplates = $scope.find('.js-render-template');
    $renderTemplates.each(function (e){
        let renderAfterLoaded = $(this).data('render-template-after-loaded') || false;

        let $renderItem = $(this);

        if(renderAfterLoaded) {
            window.onload = function () {
                renderTemplate($renderItem);
            }
        } else {
            onEnterViewPort($(this), function (renderItem) {
                renderTemplate($renderItem);
            }, { offset: 300 });
        }
    });
}

function renderTemplate(renderItem){
    let $template = renderItem.find('.js-render-template__template');
    let templateHtml = $template.html();

    renderItem.html(templateHtml).removeClass('js-render-template');
    $template.remove();
    initModulesInScope(renderItem);
}