"use strict";

export function initInScope ($scope) {
    let collapse = $scope.find(".js-accordion-collapse");
    let show = $scope.find(".js-accordion-show");
    let width = screen.width;

//first accordion closed on mobile devices - else first is open by default
    if (width <= 992){
        collapse.addClass("collapsed")
        show.removeClass("show");
    }
}

