"use strict";

import {loadParsley} from "@elements/parsley-bootstrap-validation";
import {trackEvent} from "./trackingGa4/gtm";

export function init() {

    let $form = $('.js-tracking-custom-form-error');
    let $input = $form.find('input');

    loadParsley().then(function (){
        $input.on('change', function (e){
            let isValid = $(this).parsley().validate();

            if(isValid !== true){
                let input = $(this);
                let errorMessage = input.next().find('li').text();

                let data = {
                    "datalayer": {
                        "event": "error",
                        "error_field": input.attr('name'),
                        "error_message": errorMessage
                    }
                }
                trackEvent(data, {debug: true});
            }
        });


        $form.on('submit', function (e){
            let $thisForm = $(this);
            let $inputs  = $thisForm.find('input');

            if($('.js-add-form-section__checkbox').is(":checked")){
                $inputs = $thisForm.find('input:not(.js-check-address__delivery-input)');
            }

            $inputs.each(function (index, value){
                let isValid = $(this).parsley().validate();

                if(isValid !== true){
                    let input = $(this);
                    let errorMessage = input.next().find('li').text();

                    let data = {
                        "datalayer": {
                            "event": "error",
                            "error_field": input.attr('name'),
                            "error_message": errorMessage
                        }
                    }
                    trackEvent(data, {debug: true});
                }
            });
        });
    });
}