"use strict";

export function initInScope($scope) {
    try{
        if(!window.sessionStorage.getItem('informationModal')) {
            $scope.find('#informationModal').modal('show');
        }

        $scope.find('#informationModal').on('hidden.bs.modal', function (e) {
            window.sessionStorage.setItem('informationModal', true);
        });

    }catch(e){
        console.log(e);
    }

}