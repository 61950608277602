"use strict";

import {trackEvent} from "./trackingGa4/gtm";
import {isValid, loadParsley, isParsleyForm} from "@elements/parsley-bootstrap-validation";

export function initInScope($scope) {
    $scope.find('.js-tracking-custom-select-value').on('change', function (e){
        let value = $(this).val();

        let data = {
            "datalayer": {
                "event": "listing_sort",
                "link_text": value
            }
        }
        trackEvent(data, {debug: true})
    });

    $scope.find('.js-tracking-custom-payment-form').on('submit', function (e){
        let $form = $(this);

        loadParsley().then(function () {
            if (isParsleyForm($form) && isValid($form)) {
                let trackingKey = $form.data('event-tracking-object-key-custom');
                if (trackingKey) {
                    if (_trackingData[trackingKey]) {
                        let datalayer = _trackingData[trackingKey].datalayer;

                        window.dataLayer = window['dataLayer'] || [];

                        let paymentType = $(".js-payment-group__input:checked").val();
                        datalayer = {...datalayer, 'payment_type': paymentType};

                        dataLayer.push(datalayer);
                    }
                }

            }else{
                console.warn('Form not Valid');
            }
        });

    });

    $scope.find('.magazine .wysiwyg a').on('click', function (e){
        // e.preventDefault();

        let data = {
            "datalayer": {
                "event": "select_content",
                "content_group": "magazine",
                "content_type": $(this).text(),
                "link_url": $(this).attr('href')
            }
        }

        trackEvent(data, {debug: true})
    });

}